import { setLocalStorageAuthToken } from "@streamtimefe/utils";
import type { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import type { ClientCommercialDocument } from "st-shared/entities/ClientCommercialDocument";

export function useGetCommercialDocument<T extends ClientCommercialDocument>(
  token: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getEntity: () => Promise<AxiosResponse<T, any>>
) {
  const [isFetching, setIsFetching] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [error, setError] = useState<any>(null);
  const [entity, setEntity] = useState<T | null>(null);

  useEffect(() => {
    setIsFetching(true);
    setError(null);
    setEntity(null);
    setLocalStorageAuthToken(token);

    (async () => {
      try {
        const { data } = await getEntity();
        setEntity(data);
      } catch (error: unknown) {
        setError(error);
      } finally {
        setIsFetching(false);
      }
    })();
  }, [token, getEntity]);

  return { entity, isFetching, error, setEntity };
}
