import type { ReactNode } from "react";

import {
  container,
  errorContainer,
  errorFooter,
  errorNode,
  pageBody,
} from "./ErrorModal.css";

interface Props {
  code: "401" | "404";
  children: ReactNode;
}

export function ErrorModal({ code, children }: Props) {
  return (
    <div className={container} id="error">
      <div className={pageBody}>
        <div className={errorContainer}>
          <p className={errorNode}>{children}</p>
        </div>
        {code === "404" && <div className={errorFooter}>404</div>}
      </div>
    </div>
  );
}
