import { createHashRouter, RouterProvider } from "react-router-dom";

import { Invoice } from "./pages/Invoice";
import { NoMatch } from "./pages/NoMatch";
import { PurchaseOrder } from "./pages/PurchaseOrder";
import { Quote } from "./pages/Quote";
import { Welcome } from "./pages/Welcome";

const router = createHashRouter([
  {
    path: "*",
    element: <NoMatch />,
  },
  {
    path: "/",
    element: <Welcome />,
  },
  {
    path: "/quotes/:token",
    element: <Quote />,
  },
  {
    path: "/invoices/:token",
    element: <Invoice />,
  },
  {
    path: "/purchase_orders/:token",
    element: <PurchaseOrder />,
  },
]);

export function AppRouter() {
  return <RouterProvider router={router} />;
}
