import "@streamtimefe/polyfill";

import { bugsnagStart } from "@streamtimefe/error";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import { App } from "./App";

bugsnagStart("client");

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
