import clsx from "clsx";
import { ReactNode } from "react";

import { AriaModal } from "../../components";
import { ModalFooter, ModalFooterProps } from "./ModalFooter";
import { ModalHeader, ModalHeaderProps } from "./ModalHeader";
import { modalContentCss } from "./SaveModal.css";

type Props = {
  children: ReactNode;
  width?: number;
  contentClassName?: string;
  headerProps?: ModalHeaderProps;
  footerProps: ModalFooterProps;
};

export function SaveModal({
  children,
  width = 600,
  contentClassName,
  headerProps,
  footerProps,
}: Props) {
  return (
    <AriaModal dialogProps={{ style: { width } }}>
      {headerProps && <ModalHeader {...headerProps} />}
      <div className={clsx(modalContentCss, contentClassName)}>{children}</div>
      <ModalFooter {...footerProps} />
    </AriaModal>
  );
}
