import type { MouseEventHandler, ReactNode } from "react";

import * as styles from "./TextButton.css";

interface Props {
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  text: string;
}

export function TextButton({ children, onClick, text }: Props) {
  return (
    <button className={styles.buttonCss} onClick={onClick}>
      {children && <i className={styles.iconCss}>{children}</i>}
      <span>{text}</span>
    </button>
  );
}
