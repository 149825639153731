import "st-shared/css";
import "./styles.css";

import { Toaster } from "@streamtimefe/components";

import { AppRouter } from "./AppRouter";

export function App() {
  return (
    <>
      <AppRouter />
      <Toaster />
    </>
  );
}
