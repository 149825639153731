import { ErrorModal } from "../../components/ErrorModal";

export function NoMatch() {
  return (
    <ErrorModal code="404">
      <strong>
        If there’s a bright centre of our website, you’re on the page that’s
        farthest from it.
      </strong>
      <br />
      <br /> There’s nothing here. Maybe you ended up here by mistake. Maybe we
      accidentally misled you. <a href="/">
        Either way, you need to get out.
      </a>{" "}
      While you still can.
    </ErrorModal>
  );
}
