import type { ReactNode } from "react";

import {
  actionButtons,
  actionPanelInner,
  actionPanelOuter,
} from "./ActionPanel.css";
import { SaveAsPdfButton } from "./buttons/SaveAsPdfButton";

interface Props {
  children?: ReactNode;
  pdfUrl: string;
}

export function ActionPanel({ children, pdfUrl }: Props) {
  return (
    <div className={actionPanelOuter}>
      <div className={actionPanelInner}>
        <div className={actionButtons}>{children}</div>
        <SaveAsPdfButton url={pdfUrl} />
      </div>
    </div>
  );
}
