import { ClientPurchaseOrder } from "../../entities/ClientPurchaseOrder";
import { CLIENTAPI, getAuthToken } from "../api";
import { ClientApiRoot } from "./clientApiRoot";

export const PurchaseOrdersRoot = ClientApiRoot + "/purchase_orders";

export function getHtmlUrl() {
  return CLIENTAPI.makeURL(
    `${PurchaseOrdersRoot}/html?token=${getAuthToken()}`
  );
}

export function getPdfDownloadUrl() {
  return CLIENTAPI.makeURL(
    `${PurchaseOrdersRoot}/pdfs/download?token=${getAuthToken()}`
  );
}

export function getPurchaseOrder() {
  return CLIENTAPI.get<ClientPurchaseOrder>(PurchaseOrdersRoot);
}
