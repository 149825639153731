import { toastAlert } from "@streamtimefe/components";
import moment from "moment";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import type { ClientQuote } from "st-shared/entities/ClientQuote";
import { isApproved, isFinalised } from "st-shared/entities/QuoteStatus";
import { ClientAPI } from "st-shared/lib";

import { ActionPanel } from "../../components/ActionPanel";
import { CommercialDocumentError } from "../../components/CommercialDocumentError";
import { CommercialDocumentHeader } from "../../components/CommercialDocumentHeader";
import { CommercialDocumentHtml } from "../../components/CommercialDocumentHtml";
import { LoadingContainer } from "../../components/LoadingContainer";
import { useGetCommercialDocument } from "../../hooks/useGetCommercialDocument";
import { mainContainerCss } from "../../styles.css";
import { AcceptButton } from "./AcceptButton";
import { AddPOButton } from "./AddPOButton";
import { DeclineButton } from "./DeclineButton";
import { whiteText } from "./index.css";

export function Quote() {
  const { token } = useParams();

  const {
    entity: quote,
    isFetching,
    error,
    setEntity: setQuote,
  } = useGetCommercialDocument(token!, ClientAPI.Quotes.getQuote);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  function onQuoteUpdate(quote: ClientQuote) {
    setQuote(quote);
  }

  function onPONumberUpdate(quote: ClientQuote) {
    setQuote(quote);
    if (iframeRef.current) {
      iframeRef.current.src += "";
    }
  }

  function onError(message: string) {
    toastAlert("error", message);
  }

  function getStatusButtons() {
    if (isFinalised(quote!.quoteStatus)) {
      return (
        <span>
          <span className={whiteText}>{quote!.finalisedBy}</span>
          &nbsp;{isApproved(quote!.quoteStatus) ? "accepted" : "declined"}
          &nbsp;this quote on&nbsp;
          {moment(quote!.lastStatusUpdateDatetime).format("DD/MM/YYYY")}
        </span>
      );
    }

    return (
      <>
        <AcceptButton
          quote={quote!}
          onQuoteUpdate={onQuoteUpdate}
          onError={onError}
        />
        <DeclineButton
          quote={quote!}
          onQuoteUpdate={onQuoteUpdate}
          onError={onError}
        />
        {!quote!.purchaseOrderNumber && (
          <AddPOButton
            quote={quote!}
            onQuoteUpdate={onPONumberUpdate}
            onError={onError}
          />
        )}
      </>
    );
  }

  if (isFetching) {
    return (
      <div className={mainContainerCss}>
        <LoadingContainer title="Quote" />
      </div>
    );
  }

  if (error) {
    return <CommercialDocumentError title="quote" />;
  }

  if (quote) {
    return (
      <div className={mainContainerCss}>
        <CommercialDocumentHeader defaultText="Quote from" entity={quote} />
        <ActionPanel pdfUrl={ClientAPI.Quotes.getPdfDownloadUrl()}>
          {getStatusButtons()}
        </ActionPanel>
        <CommercialDocumentHtml
          ref={iframeRef}
          title="Quote Document"
          url={ClientAPI.Quotes.getHtmlUrl()}
          template={quote.commercialDocumentTemplate}
        />
      </div>
    );
  }

  return null;
}
