import { ErrorModal } from "./ErrorModal";

type Props = {
  title: string;
};

export function CommercialDocumentError({ title }: Props) {
  return (
    <ErrorModal code="401">
      <strong>This {title} no longer exists</strong>
      <br />
      <br />
      The sender could be in the process of making changes, or this {title} has
      been deleted. Hopefully a link to your new {title} is just around the
      corner.
    </ErrorModal>
  );
}
