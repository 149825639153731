import clsx from "clsx";

import { Button, RTooltip } from "../../components";
import {
  buttonsCss,
  disabledTooltipCss,
  modalFooterCloseCss,
  modalFooterCss,
  modalFooterSaveCss,
} from "./ModalFooter.css";

export type ModalFooterProps = {
  onClose: () => void;
  closeClassName?: string;
  className?: string;
  save: {
    text: string;
    onClick: () => void;
    className?: string;
    disabled?: boolean;
    disabledTooltip?: string;
  };
  secondary?: {
    text: string;
    onClick: () => void;
    className?: string;
    disabled?: boolean;
    disabledTooltip?: string;
  };
};

export function ModalFooter({
  onClose,
  className,
  closeClassName,
  save,
  secondary,
}: ModalFooterProps) {
  return (
    <div className={clsx(modalFooterCss, className)}>
      <Button
        className={clsx(modalFooterCloseCss, closeClassName)}
        onClick={onClose}
      >
        Cancel
      </Button>
      <div className={buttonsCss}>
        {secondary && (
          <>
            <Button
              variant="secondary"
              disabled={secondary.disabled}
              onClick={secondary.onClick}
              data-tooltip-id="secondary-disabled-tooltip"
            >
              {secondary.text}
            </Button>
            {secondary.disabled && secondary.disabledTooltip && (
              <RTooltip
                id="secondary-disabled-tooltip"
                place="top"
                content={secondary.disabledTooltip}
                className={disabledTooltipCss}
              />
            )}
          </>
        )}
        <Button
          variant="primary"
          className={modalFooterSaveCss}
          disabled={save.disabled}
          onClick={save.onClick}
          data-tooltip-id="save-disabled-tooltip"
        >
          {save.text}
        </Button>
        {save.disabled && save.disabledTooltip && (
          <RTooltip
            id="save-disabled-tooltip"
            place="top"
            content={save.disabledTooltip}
            className={disabledTooltipCss}
          />
        )}
      </div>
    </div>
  );
}
