import { forwardRef } from "react";
import type { CommercialDocumentTemplate } from "st-shared/entities/CommercialDocumentTemplate";

import { iframeCss } from "./CommercialDocumentHtml.css";

interface Props {
  title: string;
  url: string;
  template: CommercialDocumentTemplate;
}

export const CommercialDocumentHtml = forwardRef<HTMLIFrameElement, Props>(
  ({ title, url }, ref) => {
    return <iframe className={iframeCss} ref={ref} title={title} src={url} />;
  }
);

CommercialDocumentHtml.displayName = "CommercialDocumentHtml";
