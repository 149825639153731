import clsx from "clsx";
import { RefAttributes } from "react";

import { RAC } from "../../../external";
import { dialogCss, modalCss, modalGutterCss, overlayCss } from "./Modal.css";

export const ModalContext = RAC.ModalContext;

export type AriaModalProps = {
  overlayProps?: Omit<RAC.ModalOverlayProps, "children"> &
    RefAttributes<HTMLDivElement>;
  modalProps?: Omit<RAC.ModalOverlayProps, "children"> &
    RefAttributes<HTMLDivElement>;
  dialogProps?: Omit<RAC.DialogProps, "children"> & RefAttributes<HTMLElement>;
  children?: RAC.DialogProps["children"];
};

export function AriaModal({
  children,
  overlayProps,
  modalProps,
  dialogProps,
}: AriaModalProps) {
  return (
    <RAC.ModalOverlay
      {...overlayProps}
      className={clsx(overlayCss, overlayProps?.className)}
    >
      <div className={modalGutterCss} />
      <RAC.Modal
        {...modalProps}
        className={clsx(modalCss, modalProps?.className)}
      >
        <RAC.Dialog
          aria-label="streamtime modal dialog"
          {...dialogProps}
          className={clsx(dialogCss, dialogProps?.className)}
        >
          {children}
        </RAC.Dialog>
      </RAC.Modal>
      <div className={modalGutterCss} />
    </RAC.ModalOverlay>
  );
}
