import type { AxiosError } from "axios";
import { produce } from "immer";
import { useEffect, useState } from "react";
import { Icon, IconSize, MdCheck } from "st-shared/components";
import type { ClientQuote } from "st-shared/entities/ClientQuote";
import { QuoteStatuses } from "st-shared/entities/QuoteStatus";
import { ClientAPI } from "st-shared/lib";

import { TextButton } from "../../components/buttons/TextButton";

interface Props {
  quote: ClientQuote;
  onQuoteUpdate: (quote: ClientQuote) => void;
  onError: (message: string) => void;
}

export function AcceptButton({ quote, onQuoteUpdate, onError }: Props) {
  const [acceptText, setAcceptText] = useState("Accept");
  const [isAccepting, setIsAccepting] = useState(false);

  useEffect(() => {
    if (quote.languageOptions && JSON.parse(quote.languageOptions).approve) {
      setAcceptText(JSON.parse(quote.languageOptions).approve);
    }
  }, [quote.languageOptions]);

  function onClick() {
    setIsAccepting(true);
    const newQuote = produce(quote, (draft) => {
      draft.quoteStatus.id = QuoteStatuses.Approved;
    });

    (async () => {
      try {
        const { data } = await ClientAPI.Quotes.putQuote(newQuote);
        onQuoteUpdate(data);
      } catch (error: unknown) {
        onError((error as AxiosError).response?.data as string);
      } finally {
        setIsAccepting(false);
      }
    })();
  }

  return (
    <TextButton
      onClick={onClick}
      text={isAccepting ? "Processing..." : acceptText}
    >
      <Icon icon={MdCheck} size={IconSize.XLarge} />
    </TextButton>
  );
}
