import { IconButton, IconSize, MdDownload } from "st-shared/components";

interface Props {
  url: string;
}

export function SaveAsPdfButton({ url }: Props) {
  function onClick() {
    window.open(url, "_blank");
  }

  return (
    <IconButton
      variant="light"
      width={45}
      height={45}
      onClick={onClick}
      iconProps={{
        icon: MdDownload,
        size: IconSize.XLarge,
        title: "Download PDF",
      }}
    />
  );
}
