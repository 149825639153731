import { useEffect, useState } from "react";
import type { ClientCommercialDocument } from "st-shared/entities/ClientCommercialDocument";

import { container } from "./CommercialDocumentHeader.css";

interface Props {
  defaultText: string;
  entity: ClientCommercialDocument;
}

export function CommercialDocumentHeader({ defaultText, entity }: Props) {
  const [headerFrom, setHeaderFrom] = useState(defaultText);

  useEffect(() => {
    if (
      entity.languageOptions &&
      JSON.parse(entity.languageOptions).headerFrom
    ) {
      setHeaderFrom(JSON.parse(entity.languageOptions).headerFrom);
    } else {
      setHeaderFrom(defaultText);
    }
  }, [defaultText, entity.languageOptions]);

  return (
    <header className={container}>
      <h1>
        {headerFrom} {entity.organisationName}
      </h1>
    </header>
  );
}
