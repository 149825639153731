import { loadingCss } from "./LoadingContainer.css";

interface Props {
  title: string;
}

export function LoadingContainer({ title }: Props) {
  return (
    <div className={loadingCss}>
      <h2>Loading {title}...</h2>
    </div>
  );
}
