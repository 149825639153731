import { ClientQuote } from "../../entities/ClientQuote";
import { CLIENTAPI, getAuthToken } from "../api";
import { ClientApiRoot } from "./clientApiRoot";

export const QuotesRoot = ClientApiRoot + "/quotes";

export function getHtmlUrl() {
  return CLIENTAPI.makeURL(`${QuotesRoot}/html?token=${getAuthToken()}`);
}

export function getPdfDownloadUrl() {
  return CLIENTAPI.makeURL(
    `${QuotesRoot}/pdfs/download?token=${getAuthToken()}`
  );
}

export function getQuote() {
  return CLIENTAPI.get<ClientQuote>(QuotesRoot);
}

export function putQuote(quote: ClientQuote) {
  return CLIENTAPI.put<ClientQuote>(QuotesRoot, quote);
}
