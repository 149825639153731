import type { AxiosError } from "axios";
import { produce } from "immer";
import { useMemo, useState } from "react";
import { Input, ModalContext } from "st-shared/components";
import type { ClientQuote } from "st-shared/entities/ClientQuote";
import { ClientAPI } from "st-shared/lib";
import { SaveModal } from "st-shared/module";

import { TextButton } from "../../components/buttons/TextButton";
import { modalText } from "./index.css";

interface Props {
  quote: ClientQuote;
  onQuoteUpdate: (quote: ClientQuote) => void;
  onError: (message: string) => void;
}

export function AddPOButton({ quote, onQuoteUpdate, onError }: Props) {
  const [isOpen, setOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState("");

  function openModal() {
    setOpen(true);
    setPurchaseOrderNumber(
      quote.purchaseOrderNumber ? String(quote.purchaseOrderNumber) : ""
    );
  }

  function closeModal() {
    setOpen(false);
    setPurchaseOrderNumber("");
  }

  function onSave() {
    setIsSaving(true);
    const newQuote = produce(quote, (draft) => {
      draft.purchaseOrderNumber = purchaseOrderNumber;
    });

    (async () => {
      try {
        const { data } = await ClientAPI.Quotes.putQuote(newQuote);
        onQuoteUpdate(data);
        closeModal();
      } catch (error: unknown) {
        onError((error as AxiosError).response?.data as string);
      } finally {
        setIsSaving(false);
      }
    })();
  }

  const validationError = useMemo<string | undefined>(() => {
    if (purchaseOrderNumber.length === 0) {
      return "PO Number cannot be empty.";
    }
    return undefined;
  }, [purchaseOrderNumber]);

  return (
    <ModalContext.Provider value={{ isOpen, onOpenChange: setOpen }}>
      <TextButton onClick={openModal} text="Add PO Number"></TextButton>
      <SaveModal
        headerProps={{
          title: "Add PO Number",
          onClose: closeModal,
        }}
        footerProps={{
          onClose: closeModal,
          save: {
            text: isSaving ? "Saving..." : "Save",
            disabled: isSaving || Boolean(validationError),
            onClick: onSave,
            disabledTooltip: validationError,
          },
        }}
        width={450}
      >
        <div className={modalText}>
          Please add a Purchase Order number that is associated with this quote.
        </div>
        <Input
          value={purchaseOrderNumber}
          variant="primary"
          type="text"
          placeholder="PO No."
          onChange={(event) => setPurchaseOrderNumber(event.target.value)}
        />
      </SaveModal>
    </ModalContext.Provider>
  );
}
