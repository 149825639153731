import toast from "react-hot-toast";

export function toastAlert(type: "success" | "error", message: string) {
  switch (type) {
    case "error":
      toast.error(message);
      break;
    case "success":
      toast.success(message);
      break;
  }
}
