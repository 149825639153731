import { useParams } from "react-router-dom";
import { ClientAPI } from "st-shared/lib";

import { ActionPanel } from "../../components/ActionPanel";
import { CommercialDocumentError } from "../../components/CommercialDocumentError";
import { CommercialDocumentHeader } from "../../components/CommercialDocumentHeader";
import { CommercialDocumentHtml } from "../../components/CommercialDocumentHtml";
import { LoadingContainer } from "../../components/LoadingContainer";
import { useGetCommercialDocument } from "../../hooks/useGetCommercialDocument";
import { mainContainerCss } from "../../styles.css";

export function Invoice() {
  const { token } = useParams();

  const {
    entity: invoice,
    isFetching,
    error,
  } = useGetCommercialDocument(token!, ClientAPI.Invoices.getInvoice);

  if (isFetching) {
    return (
      <div className={mainContainerCss}>
        <LoadingContainer title="Invoice" />
      </div>
    );
  }

  if (error) {
    return <CommercialDocumentError title="invoice" />;
  }

  if (invoice) {
    return (
      <div className={mainContainerCss}>
        <CommercialDocumentHeader defaultText="Invoice from" entity={invoice} />
        <ActionPanel pdfUrl={ClientAPI.Invoices.getPdfDownloadUrl()} />
        <CommercialDocumentHtml
          title="Invoice Document"
          url={ClientAPI.Invoices.getHtmlUrl()}
          template={invoice.commercialDocumentTemplate}
        />
      </div>
    );
  }

  return null;
}
