import { images } from "@streamtimefe/assets";

import { mainContainerCss } from "../../styles.css";
import { container, header, logo, title } from "./index.css";

export function Welcome() {
  return (
    <div className={mainContainerCss}>
      <div className={container}>
        <div className={header}>
          <img src={images.streamtimeLogo} className={logo} alt="logo" />
        </div>
        <h1 className={title}>Welcome to Streamtime Client App</h1>
      </div>
    </div>
  );
}
