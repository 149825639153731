import type { AxiosError } from "axios";
import { produce } from "immer";
import { useEffect, useState } from "react";
import { Icon, IconSize, MdClose } from "st-shared/components";
import type { ClientQuote } from "st-shared/entities/ClientQuote";
import { QuoteStatuses } from "st-shared/entities/QuoteStatus";
import { ClientAPI } from "st-shared/lib";

import { TextButton } from "../../components/buttons/TextButton";

interface Props {
  quote: ClientQuote;
  onQuoteUpdate: (quote: ClientQuote) => void;
  onError: (message: string) => void;
}

export function DeclineButton({ quote, onQuoteUpdate, onError }: Props) {
  const [declineText, setDeclineText] = useState("Decline");
  const [isDeclining, setIsDeclining] = useState(false);

  useEffect(() => {
    if (quote.languageOptions && JSON.parse(quote.languageOptions).decline) {
      setDeclineText(JSON.parse(quote.languageOptions).decline);
    }
  }, [quote.languageOptions]);

  function onClick() {
    setIsDeclining(true);
    const newQuote = produce(quote, (draft) => {
      draft.quoteStatus.id = QuoteStatuses.Declined;
    });

    (async () => {
      try {
        const { data } = await ClientAPI.Quotes.putQuote(newQuote);
        onQuoteUpdate(data);
      } catch (error: unknown) {
        onError((error as AxiosError).response?.data as string);
      } finally {
        setIsDeclining(false);
      }
    })();
  }

  return (
    <TextButton
      onClick={onClick}
      text={isDeclining ? "Processing..." : declineText}
    >
      <Icon icon={MdClose} size={IconSize.XLarge} />
    </TextButton>
  );
}
