import { ClientInvoice } from "../../entities/ClientInvoice";
import { CLIENTAPI, getAuthToken } from "../api";
import { ClientApiRoot } from "./clientApiRoot";

export const InvoicesRoot = ClientApiRoot + "/invoices";

export function getHtmlUrl() {
  return CLIENTAPI.makeURL(`${InvoicesRoot}/html?token=${getAuthToken()}`);
}

export function getPdfDownloadUrl() {
  return CLIENTAPI.makeURL(
    `${InvoicesRoot}/pdfs/download?token=${getAuthToken()}`
  );
}

export function getInvoice() {
  return CLIENTAPI.get<ClientInvoice>(InvoicesRoot);
}
