import clsx from "clsx";
import { ReactNode } from "react";

import { IconButton, IconSize, MdClose } from "../../components";
import {
  modalHeaderCss,
  modalHeaderNodeCss,
  modalHeaderTextCss,
} from "./ModalHeader.css";

export type ModalHeaderProps = {
  title: string;
  onClose?: () => void;
  className?: string;
  leftNode?: ReactNode;
  rightNode?: ReactNode;
};

export function ModalHeader({
  title,
  onClose,
  className,
  leftNode,
  rightNode = onClose && (
    <IconButton
      onClick={onClose}
      iconProps={{ icon: MdClose, size: IconSize.XXLarge }}
    />
  ),
}: ModalHeaderProps) {
  return (
    <div className={clsx(modalHeaderCss, className)}>
      <div className={modalHeaderNodeCss}>{leftNode}</div>
      <h1 className={modalHeaderTextCss}>{title}</h1>
      <div className={modalHeaderNodeCss}>{rightNode}</div>
    </div>
  );
}
